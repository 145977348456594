import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setActiveLesson, setActiveSubLesson } from "../../../../actions";
import { use } from "react";

const Link = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const intervention_id = useSelector((state) => state.intervention.id);

  const linkTo = (e, lesson_id) => {
    e.preventDefault();
    //first character of lesson_id is 'l' or 'o' (lesson or optional lesson), so we need to remove it:
    let lesson_type = lesson_id.toString().substring(0, 1);
    lesson_id = parseInt(lesson_id.substring(1));
    dispatch(setActiveSubLesson(0));
    dispatch(setActiveLesson(lesson_id));
    if (lesson_type === "o") {
      history.push(
        "/course/" + intervention_id + "/optional-lesson/" + lesson_id + "/0"
      );
    } else {
      history.push(
        "/course/" + intervention_id + "/lesson/" + lesson_id + "/0"
      );
    }
  };

  return (
    <div
      style={{ marginTop: "20px", marginBottom: "20px", textAlign: "center" }}
    >
      {props.part.link_type === "button" ? (
        <div
          className="btn btn-primary"
          onClick={(e) => linkTo(e, props.part.lesson_id)}
          dangerouslySetInnerHTML={{ __html: props.part.link_text }}
        />
      ) : (
        <a
          href="#"
          onClick={(e) => linkTo(e, props.part.lesson_id)}
          dangerouslySetInnerHTML={{ __html: props.part.link_text }}
        />
      )}
    </div>
  );
};

export default Link;
