import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setChosenImage } from "../../../../actions";
import InputTextfield from './input_textfield.js';
import t from "../../../translate";

const Link = (props) => {

    const intervention = useSelector(state => state.intervention);

  return (
    <div className='link center'>
        <div className="col">
            <label htmlFor="link_text">{t("Button/Link tekst")}</label>
            <input
              type="text"
              className="form-control"
              id="link_text"
              name="link_text"
              aria-describedby="phone"
              placeholder=""
              value={props.part.link_text}
              onChange={(e) => props.updatePart(props.index, "link_text", e.target.value)}
            />
        </div>
        <div className="col">
            <label htmlFor="lesson_id">{t("Link to lesson")}</label>
            <select
              id="lesson_id"
              name="lesson_id"
              className="form-control"
              value={props.part.lesson_id}
              onChange={(e) => props.updatePart(props.index, "lesson_id", e.target.value)}
            >
                {
                  intervention.settings.selfhelp.lessons.map((lesson, index) => {
                    if (parseInt(lesson.parent_id) === 0) {
                       return (
                        <option key={index} value={'l'+lesson.id}>
                          {lesson.title}
                        </option>
                       )
                    } else {
                        return (
                        <option key={index} value={'l'+lesson.id}>
                          &nbsp; &nbsp; &nbsp; {lesson.title}
                        </option>
                        )
                    }
                 })
                }
                {
                  intervention.settings.selfhelp.optionalLessons.map((lesson, index) => {
                    if (parseInt(lesson.parent_id) === 0) {
                       return (
                        <option key={index} value={'o'+lesson.id}>
                          {lesson.title}
                        </option>
                       )
                    } else {
                        return (
                        <option key={index} value={'o'+lesson.id}>
                          &nbsp; &nbsp; &nbsp; {lesson.title}
                        </option>
                        )
                    }
                 })
                }
            </select>
        </div>
        <div className="col" style={{marginTop: "20px"}}>
            &nbsp;
            <input type="radio" name={'link_type_'+props.index} id={'link_type_'+props.index+'_button'} value="button" checked={true} onChange={()=>props.updatePart(props.index, "link_type", 'button')} />
            <label htmlFor="link_type_button" onClick={()=>props.updatePart(props.index, "link_type", 'button')} >{t("Button")}</label>
            <input type="radio" name={'link_type_'+props.index} id={'link_type_'+props.index+'_link'} value="link" checked={props.part.link_type == "link" ? true:false} onChange={()=>props.updatePart(props.index, "link_type", 'link')} />
            <label htmlFor="link_type_link" onClick={()=>props.updatePart(props.index, "link_type", 'link')} >{t("Link")}</label>
        </div>
        <div style={{marginTop: "20px", textAlign: "center"}}>
            {
                props.part.link_type === "button" ?
                    <div className="btn btn-primary">{props.part.link_text}</div>
                    :
                    <a href="#">{props.part.link_text}</a>
            }
        </div>
    </div>
  );
}

export default Link;
