import React, { useState, useEffect } from "react";
import DynamicContent from "./studentDetails/dynamiccontent.js";
import { useSelector, useDispatch } from "react-redux";
import LoadScreen from "../loadScreen/index.js";
import apiCall from "../api";
import t from "../translate";
import { setIntervention as setInterventionRedux } from "../../actions";

const StudentDetails = ({
  studentId,
  studentName,
  showUnseenLessonBadge,
  showUnreadMessagesBadge,
  goTo,
  interventions,
  setInterventions,
  setUnreadMessageMain,
  setUnseenLessonMain,
  setDetailsVisible,
}) => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const uiTranslation = useSelector((state) => state.uiTranslation);

  //const intervention = useSelector(state => state.intervention);
  const [isLoading, setIsLoading] = useState(true);
  const [navtab, setNavtab] = useState("info");
  const [selfRegisterd, setSelfRegisterd] = useState(false);
  const [unreadMessage, setUnreadMessage] = useState(false);
  const [unseenLesson, setUnseenLesson] = useState(false);
  const [dynamicContentType, setDynamicContentType] = useState("info");
  const [intervention, setIntervention] = useState({});
  const [student, setStudent] = useState({});
  const [coaches, setCoaches] = useState([]);

  useEffect(() => {
    setUnseenLesson(showUnseenLessonBadge);
    setUnreadMessage(showUnreadMessagesBadge);
  }, [showUnseenLessonBadge, showUnreadMessagesBadge]);

  useEffect(() => {
    setIsLoading(true);
    //info student ophalen
    apiCall({
      action: "get_student",
      token: auth.token,
      data: {
        user_id: studentId,
      },
    }).then((resp) => {
      setStudent(resp.user);
      if (
        typeof resp.user.rights !== "undefined" &&
        resp.user.rights.interventions.length > 0 &&
        typeof resp.user.rights.interventions[0]["id"] !== "undefined"
      ) {
        if (
          typeof interventions[resp.user.rights.interventions[0]["id"]]
            .settings === "undefined"
        ) {
          apiCall({
            action: "get_intervention_settings",
            token: auth.token,
            data: {
              intervention_id: resp.user.rights.interventions[0]["id"],
              include_forms: true, //tijdelijk...
              language_id: uiTranslation.language_id,
            },
          }).then((resp) => {
            let newInterventions = { ...interventions };
            newInterventions[resp.intervention_id].settings = resp.settings;
            setInterventions(newInterventions);
            setIntervention(newInterventions[resp.intervention_id]);
            //ook intervention in redux plaatsen tbv feedback feature...
            dispatch(
              setInterventionRedux(
                resp.intervention_id,
                resp.organisation_id,
                resp.title,
                resp.settings
              )
            );
            setIsLoading(false);
          });
        } else {
          setIntervention(
            interventions[resp.user.rights.interventions[0]["id"]]
          );
          dispatch(
            setInterventionRedux(
              resp.user.rights.interventions[0]["id"],
              1,
              interventions[resp.user.rights.interventions[0]["id"]].title,
              interventions[resp.user.rights.interventions[0]["id"]].settings
            )
          );

          setIsLoading(false);
        }

        if (resp.user.registration) {
          setSelfRegisterd(resp.user.registration);
        } else {
          setSelfRegisterd(false);
          navigateTo("info");
        }
      } else {
        setIsLoading(false);
      }
    });

    /* if(props.goTo != ""){
        navigateTo(props.goTo)
      }
      if(props.user.registration){
        setSelfRegisterd(props.user.registration)
      } else {
        setSelfRegisterd(false)
        navigateTo('info')
      }
      if (typeof props.user.unread_message !== undefined)
      {
        setUnreadMessage(props.user.unread_message);
      }
      if (typeof props.user.unseen_lesson !== undefined)
      {
        setUnseenLesson(props.user.unseen_lesson);
      } */

    //setNavtab("info")
    //setDynamicContentType("info")
  }, [studentId]);

  useEffect(() => {
    if (goTo !== "") {
      navigateTo(goTo);
    }
  }, [goTo]);

  const navigateTo = (gotab) => {
    if (navtab !== gotab) {
      setNavtab(gotab);
      setDynamicContentType(gotab);
    }
  };

  return (
    <table className="detailsTable">
      <tr>
        <td className="details">
          <h1>
            #{studentId} {studentName}
          </h1>

          <div className="student_details">
            <div className="navbarHolder">
              {Object.keys(student).length > 0 ? (
                //<table >
                //  <tbody>
                //    <tr>
                //      <td className='filterd'>

                //    </td>
                //  <td>
                <>
                  {isLoading ? (
                    <LoadScreen />
                  ) : (
                    <>
                      <div className="close">
                        <i
                          className="fas fa-times pointer"
                          onClick={() => setDetailsVisible(false)}
                        ></i>
                      </div>
                      <nav className="navbar navbar-intervention-settings navbar-expand-lg navbar-light">
                        <div
                          className="collapse navbar-collapse"
                          id="navbarNavAltMarkup"
                        >
                          <div className="navbar-nav">
                            <span
                              id="settings-navbar-info"
                              className={
                                navtab == "info"
                                  ? "nav-item nav-link active"
                                  : "nav-item nav-link"
                              }
                              onClick={() => {
                                navigateTo("info");
                              }}
                            >
                              {t("Profiel")}
                            </span>
                            {selfRegisterd ? (
                              <span
                                id="settings-navbar-registration"
                                className={
                                  navtab == "registration"
                                    ? "nav-item nav-link active"
                                    : "nav-item nav-link"
                                }
                                onClick={() => {
                                  navigateTo("registration");
                                }}
                              >
                                {t("Aanmeld data")}
                              </span>
                            ) : (
                              ""
                            )}
                            <span
                              className={
                                intervention.hasOwnProperty("settings") &&
                                intervention.settings.selfhelp
                                  .guided_selfhelp_chat_contact === 1
                                  ? ""
                                  : "hidden"
                              }
                            >
                              <span
                                id="settings-navbar-chat"
                                className={
                                  navtab == "chat"
                                    ? "nav-item nav-link active"
                                    : "nav-item nav-link"
                                }
                                onClick={() => {
                                  navigateTo("chat");
                                }}
                              >
                                {t("Chat")}
                                &nbsp;
                                {unreadMessage ? (
                                  <span
                                    className="badge badge-success"
                                    id={"chatBadge_profile_" + student.id}
                                  >
                                    <i className="far fa-comments"></i>
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </span>
                            </span>
                            <span
                              className={
                                intervention.hasOwnProperty("settings") &&
                                intervention.settings.include_stress_meter === 1
                                  ? ""
                                  : "hidden"
                              }
                            >
                              <span
                                id="settings-navbar-stress"
                                className={
                                  navtab == "stress"
                                    ? "nav-item nav-link active"
                                    : "nav-item nav-link"
                                }
                                onClick={() => {
                                  navigateTo("stress");
                                }}
                              >
                                {t("Stress")}
                              </span>
                            </span>
                            <span
                              className={
                                intervention.hasOwnProperty("settings") &&
                                intervention.settings.include_journal === 1
                                  ? ""
                                  : "hidden"
                              }
                            >
                              <span
                                id="settings-navbar-stress"
                                className={
                                  navtab == "journal"
                                    ? "nav-item nav-link active"
                                    : "nav-item nav-link"
                                }
                                onClick={() => {
                                  navigateTo("journal");
                                }}
                              >
                                {t("Dagboek")}
                              </span>
                            </span>
                            {typeof intervention.settings !== "undefined" &&
                            typeof intervention.settings.include_sleepdiary !==
                              "undefined" &&
                            intervention.settings.include_sleepdiary === 1 ? (
                              <span>
                                <span
                                  id="settings-navbar-sleepdiary"
                                  className={
                                    navtab == "sleepdiary"
                                      ? "nav-item nav-link active"
                                      : "nav-item nav-link"
                                  }
                                  onClick={() => {
                                    navigateTo("sleepdiary");
                                  }}
                                >
                                  {t("Slaapdagboek")}
                                </span>
                              </span>
                            ) : (
                              false
                            )}
                            <span
                              className={
                                intervention.hasOwnProperty("settings") &&
                                intervention.settings.selfhelp.lessons.length >
                                  0 &&
                                intervention.settings.selfhelp
                                  .guided_selfhelp_view_lessons === 1
                                  ? ""
                                  : "hidden"
                              }
                            >
                              <span
                                id="settings-navbar-lessons"
                                className={
                                  navtab == "lessons"
                                    ? "nav-item nav-link active"
                                    : "nav-item nav-link"
                                }
                                onClick={() => {
                                  navigateTo("lessons");
                                }}
                              >
                                {t("Lessen")}
                                &nbsp;
                                {unseenLesson ? (
                                  <span
                                    className="badge badge-warning"
                                    id={"lessonBadge_profile_" + student.id}
                                  >
                                    <i className="fas fa-atlas"></i>
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </span>
                            </span>
                            <span
                              className={
                                intervention.hasOwnProperty("settings") &&
                                intervention.settings.questionnaires.length >
                                  0 &&
                                intervention.settings.selfhelp
                                  .guided_selfhelp_view_questionnaires === 1
                                  ? ""
                                  : "hidden"
                              }
                            >
                              <span
                                id="settings-navbar-questionnaires"
                                className={
                                  navtab == "questionnaires"
                                    ? "nav-item nav-link active"
                                    : "nav-item nav-link"
                                }
                                onClick={() => {
                                  navigateTo("questionnaires");
                                }}
                              >
                                {t("Vragenlijsten")}
                              </span>
                            </span>
                            <span
                              className={
                                intervention.hasOwnProperty("settings") &&
                                intervention.settings.goals.length > 0 &&
                                intervention.settings.selfhelp
                                  .guided_selfhelp_view_goals === 1
                                  ? ""
                                  : "hidden"
                              }
                            >
                              <span
                                id="settings-navbar-goals"
                                className={
                                  navtab == "goals"
                                    ? "nav-item nav-link active"
                                    : "nav-item nav-link"
                                }
                                onClick={() => {
                                  navigateTo("goals");
                                }}
                              >
                                {t("Doelen")}
                              </span>
                            </span>
                            <span
                              className={
                                intervention.hasOwnProperty("settings") &&
                                intervention.settings.selfhelp
                                  .guided_selfhelp_view_log === 1
                                  ? ""
                                  : "hidden"
                              }
                            >
                              <span
                                id="settings-navbar-log"
                                className={
                                  navtab == "log"
                                    ? "nav-item nav-link active"
                                    : "nav-item nav-link"
                                }
                                onClick={() => {
                                  navigateTo("log");
                                }}
                              >
                                {t("Log")}
                              </span>
                            </span>
                            <span>
                              <span
                                id="settings-navbar-emails"
                                className={
                                  navtab == "emails"
                                    ? "nav-item nav-link active"
                                    : "nav-item nav-link"
                                }
                                onClick={() => {
                                  navigateTo("emails");
                                }}
                              >
                                {t("Emails")}
                              </span>
                            </span>
                          </div>
                        </div>
                      </nav>
                      <div className="studentDetailsDynamicContent">
                        <DynamicContent
                          studentId={studentId}
                          dynamicContentType={dynamicContentType}
                          user={student}
                          intervention={intervention}
                          coaches={coaches}
                          setUnreadMessage={setUnreadMessage}
                          setUnreadMessageMain={setUnreadMessageMain}
                          setUnseenLesson={setUnseenLesson}
                          setUnseenLessonMain={setUnseenLessonMain}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </td>
      </tr>
    </table>
  );
};

export default StudentDetails;
