import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ConfettiFullScreen from "./confettiFullscreen";
import LessonsProgressbar from "./lessons_progressbar";
import OptionalLessons from "./optional_lessons.js";
import { setFinishedCourse, setActivePart, setActiveLesson} from "../../../actions";
import Typewriter from 'typewriter-effect';
import Rewards from "../../rewards";
import doneSVG from '../../../images/course/standard/svg/done.svg';
import lockedSVG from '../../../images/course/standard/svg/locked.svg';
import activeSVG from '../../../images/course/standard/svg/active.svg';
import NotificationBox from '../../alert/notification';
import t from "../../translate";

const Lessons = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const auth = useSelector(state => state.auth);
  const intervention = useSelector(state => state.intervention);
  const allAnswers = useSelector(state => state.answersLessons);
  const courseFinished = useSelector(state => state.finishedCourse);

  const [lessonList, setLessonList] = useState([]);
  const [illustration, setIllustration] = useState('');
  const [lessonsIntro, setLessonsIntro] = useState('');
  const [notificationOptions, setNotificationOptions] = useState('');
  const [progressBarProgress, setProgressBarProgress] = useState(0);
  const [progressBarPopoverText, setProgressBarPopoverText ] = useState('');

  const lessonFinished = (lesson_id) => {
    let currentLessonAnswers = allAnswers.answers.find((answer) => {
      return parseInt(answer.the_id) === parseInt(lesson_id)
    });
    if (typeof currentLessonAnswers !== 'undefined' &&  currentLessonAnswers.hasOwnProperty('finished') && currentLessonAnswers.finished === true)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  const getActiveLessonLink = (lesson_id, index) => {
    if(lessonList[index].status == 'active'){

      let aFinishedLesson = false;
      let anActiveLesson = false;
      for (const lesson of intervention.settings.selfhelp.lessons) {

        if ((lesson.id == lesson_id) || (parseInt(lesson.parent_id) === parseInt(lesson_id) && (!lesson.hasOwnProperty('sub_id') || parseInt(lesson.sub_id) === 0)))
        {
          if (!anActiveLesson && !lessonFinished(lesson.id))
          {
            lesson_id = lesson.id
            break;
          }
        }
      }
      return lesson_id
    } else {
      return lesson_id
    }
  }
  const changeActiveLesson = (lesson_id, index) => {

    //tbv iSleep & BioClock checken of les al is opengesteld -> verwijderd op 21-11-2022
    //if (hasAccessToLessons(lesson_id)) {
      /// ga na welke les actief is in de actieve les
      lesson_id = getActiveLessonLink(lesson_id, index)
      dispatch(setActivePart("lesson"));
      dispatch(setActiveLesson(lesson_id));
      history.push("/course/" + intervention.id + "/lesson/" + lesson_id);
    /* } else {
      setNotificationOptions({
        show: "true",
        text: t("Deze module is toegankelijk vanaf") + " " + getsAccessToLessonsWhen() + " " + t("Vul tot deze tijd dagelijks je slaapdagboek in, dit is belangrijk voordat je verder gaat met het programma."),
        confirmText: t("Ok")
      })
    } */
  }

  const fakeEmptyFunc = () => {
    //om react tevreden te stellen
  }

  useEffect(() => {
    //lessonsIntro
    if(typeof intervention.settings.lessonsIntro !== 'undefined' &&  intervention.settings.lessonsIntro !== '') {
      setLessonsIntro(intervention.settings.lessonsIntro);
    }
  }, [auth, intervention]);

  //actieve les bepalen -> les waar nog onafgeronde lessen in zitten
  useEffect(() => {

    if (intervention.settings.selfhelp.lessons.length < 1) {
        return;
    }

    let newlessonList = [];
    let unregisteredLesson = {
      id: 0,
      title: '',
      status: 'closed'
    };
    let lastLessonStatus = 'finished';
    let allFinished = true;
    let someFinished = false;
    let lastFinished = false;

    //popuptext tbv evt progressbar setten:
    if (typeof intervention.settings.selfhelp.include_progressbar !== 'undefined' && intervention.settings.selfhelp.include_progressbar && intervention.settings.selfhelp.lessons.length > 0 && typeof intervention.settings.selfhelp.progressbar_popover_text['progressbar_popover_0'] !== 'undefined') {
      setProgressBarPopoverText(intervention.settings.selfhelp.progressbar_popover_text['progressbar_popover_0']);
    }
    //tbv progress:
    let totalLessons = 0;
    let finishedLessons = 0;

    for (const lesson of intervention.settings.selfhelp.lessons) {

      totalLessons++;

      if (parseInt(lesson.parent_id) === 0 && parseInt(lesson.sub_id) === 0) //les op hoofdniveau
      {

        if (unregisteredLesson.title.length > 0)
        {
          //if (allFinished)
          if (lastFinished)
          {
            unregisteredLesson.status = 'finished';
            //popuptext tbv evt progressbar setten:
            if (typeof intervention.settings.selfhelp.include_progressbar !== 'undefined' && intervention.settings.selfhelp.include_progressbar && typeof intervention.settings.selfhelp.progressbar_popover_text['progressbar_popover_' + lesson.id] !== 'undefined') {
              setProgressBarPopoverText(intervention.settings.selfhelp.progressbar_popover_text['progressbar_popover_' + lesson.id]);
            }
          }
          else
          {
            if (someFinished)
            {
              unregisteredLesson.status = 'active';
            }
            else
            {
              unregisteredLesson.status = 'closed';
            }
          }
          if (unregisteredLesson.status === 'closed' && lastLessonStatus === 'finished')
          {
            unregisteredLesson.status = 'active';
          }
          lastLessonStatus = unregisteredLesson.status;
          newlessonList.push(unregisteredLesson);
          if (unregisteredLesson.status === 'active') {
            let activeLesson = intervention.settings.selfhelp.lessons.find(lesson => {
              return parseInt(lesson.id) === parseInt(unregisteredLesson.id)
            })
            setIllustration(typeof activeLesson.settings.image != 'undefined' ? activeLesson.settings.image :'')
          }
        } else {
          //tbv de 1e module...
          //popuptext tbv evt progressbar setten:
          //Maar alleen als er een start is gemaakt met module 1
          let lessonAnswers = allAnswers.answers.find((answer) => {
            return parseInt(answer.the_id) === parseInt(lesson.id)
          });
          if (typeof lessonAnswers !== 'undefined' && lessonAnswers.hasOwnProperty('finished') && lessonAnswers.finished) {
            if (typeof intervention.settings.selfhelp.include_progressbar !== 'undefined' && intervention.settings.selfhelp.include_progressbar && typeof intervention.settings.selfhelp.progressbar_popover_text['progressbar_popover_' + lesson.id] !== 'undefined') {
              setProgressBarPopoverText(intervention.settings.selfhelp.progressbar_popover_text['progressbar_popover_' + lesson.id]);
            }
          }
        }
        unregisteredLesson = {
          id: lesson.id,
          title: lesson.title,
          status: 'closed'
        };
        allFinished = true;
        someFinished = false;
      }

      //bepalen of les is afgerond...
      let lessonAnswers = allAnswers.answers.find((answer) => {
        return parseInt(answer.the_id) === parseInt(lesson.id)
      });
      if (typeof lessonAnswers === 'undefined' || !lessonAnswers.hasOwnProperty('finished') || lessonAnswers.finished === false)
      {
        allFinished = false;
        lastFinished = false;
      }
      else
      {
        lastFinished = true;
        someFinished = true;
        finishedLessons++;
      }
    }

    setProgressBarProgress(finishedLessons / totalLessons * 100);

    //if (allFinished)
    if (lastFinished)
    {
      unregisteredLesson.status = 'finished';

      //programma afgerond:
      if (typeof intervention.settings.selfhelp.include_progressbar !== 'undefined' && intervention.settings.selfhelp.include_progressbar && intervention.settings.selfhelp.lessons.length > 0 && typeof intervention.settings.selfhelp.progressbar_popover_text['progressbar_popover_00'] !== 'undefined') {
        setProgressBarPopoverText(intervention.settings.selfhelp.progressbar_popover_text['progressbar_popover_00']);
      }
      
    }
    else
    {
      if (someFinished)
      {
        unregisteredLesson.status = 'active';
      }
      else
      {
        unregisteredLesson.status = 'closed';
      }
    }
    if (unregisteredLesson.status === 'closed' && lastLessonStatus === 'finished')
    {
      unregisteredLesson.status = 'active';
    }
    newlessonList.push(unregisteredLesson)
    if (unregisteredLesson.status === 'active') {
      let activeLesson = intervention.settings.selfhelp.lessons.find(lesson => {
        return parseInt(lesson.id) === parseInt(unregisteredLesson.id)
      })
      setIllustration(typeof activeLesson.settings.image != 'undefined' ? activeLesson.settings.image :'')
    }

    if(allFinished){ /// find last lesson image and set image of that lesson
      for(let i = intervention.settings.selfhelp.lessons.length - 1 ; i >= 0 ; i-- ){
        if(intervention.settings.selfhelp.lessons[i].parent_id == 0){
          setIllustration(typeof intervention.settings.selfhelp.lessons[i].settings.image != 'undefined' ? intervention.settings.selfhelp.lessons[i].settings.image :'')
          break;
        }
      }

    }
    //opschonen -> tbv bug dat niet iedere les altijd op finished wordt gezet en er dan meerdere lessen actief zijn...
    let foundactive = false;
    for (var i = newlessonList.length -1;i>=0;i--) {
      if (newlessonList[i].status === 'active')
      {
        if (!foundactive)
        {
          foundactive = true;
        }
        else
        {
          newlessonList[i].status = 'finished';
        }
      }
    }
    setLessonList(newlessonList);

    if(courseFinished) {
      setEndCourse('true');
      dispatch(setFinishedCourse(false));

      /// call maken geen andere functie die einde markeert volgens mij
      // => jawel, in end_lesson in de api
      /* apiCall({
        action: "add_points",
        token: getCookie("token"),
        data:{
          intervention_id:intervention.id,
          type:"finish_intervention",
        }
      }).then(resp => {

      }); */
    }

  }, [intervention, allAnswers]);

  const [endCourse, setEndCourse] = useState(false)

  const hasAccessToLessons = (lesson_id) => {
    //Deelnemers van iSleep & BioClock krijgen pas na 6 dagen toegang tot de oefeningen en modules (behalve intro)
    /* if (typeof intervention.id !== 'undefined' && parseInt(intervention.id) === 26 && parseInt(lesson_id) !== 1278) { //alleen testen voor interventie 26, anders true
      if (typeof allAnswers.answers !== 'undefined') { //zijn er antwoorden, anders false
        //answer item voor lesson met id 1282 opzoeken
        let lessonIndex = allAnswers.answers.findIndex((element) => {
          return parseInt(element.the_id) === 1282
        })
        if (typeof lessonIndex !== 'undefined' && lessonIndex > -1 && typeof allAnswers.answers[lessonIndex].startTimestamp !== 'undefined') { //startTimestamp al gezet?
          var today = new Date();
          var sixdaysago = new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours(), today.getMinutes(), today.getSeconds());
          //var sixDaysAgoTimestamp = (sixdaysago.getTime()/1000) - (60*60*24*6);
          var sixDaysAgoTimestamp = (sixdaysago.getTime()/1000) - (60*60);
          if (allAnswers.answers[lessonIndex].finished && allAnswers.answers[lessonIndex].startTimestamp < sixDaysAgoTimestamp) {
            return true;
          }
        }
      }
      return false;
    } */
    return true;
  }

  const getsAccessToLessonsWhen = () => {
    //Deelnemers van iSleep & BioClock krijgen pas na 6 dagen toegang tot de oefeningen (tools) en de modules (behalve intro)
    /* if (typeof intervention.id !== 'undefined' && parseInt(intervention.id) === 26) {
      if (typeof allAnswers.answers !== 'undefined') {
        //answer item voor lesson met id 1282 opzoeken
        let lessonIndex = allAnswers.answers.findIndex((element) => {
          return parseInt(element.the_id) === 1282
        })
        if (typeof lessonIndex !== 'undefined' && lessonIndex > -1 && typeof allAnswers.answers[lessonIndex].startTimestamp !== 'undefined') {
          //var date = new Date((parseInt(allAnswers.answers[lessonIndex].startTimestamp) + (60*60*24*6)) * 1000);
          var date = new Date((parseInt(allAnswers.answers[lessonIndex].startTimestamp) + (60*60)) * 1000);
          return date.getDate()+"-"+(date.getMonth() + 1)+"-"+date.getFullYear()+" "+date.getHours()+":"+(date.getMinutes()<10?'0':'') + date.getMinutes();
        }
      }
    } */
    return ''
  }

  const goBaseline = () => {
    dispatch(setActivePart("baseline"));
    history.push("/baseline/");
  }

  return(
    <div className='lessons'>
        <div className="welkom">{t("Welkom bij")}</div>
        <div className="title"><span dangerouslySetInnerHTML={{__html: intervention.title}} /></div>
      {/* {typeof intervention.settings.subtitle !== "undefined" && intervention.settings.subtitle !== "" ?
        <div className="title">
          <h1 id="typed_1" className="subTitle"><Typewriter options={{delay:40}}
          onInit={(typewriter) => {
            typewriter.typeString(intervention.settings.subtitle)
              .callFunction(() => {
                document.getElementById("typed_1").className = "finished subTitle"
              })
              //.pauseFor(2500)
              //.deleteAll()
              .start();
          }}
          /></h1>
          <h1 className="dummy">{intervention.settings.subtitle}</h1>
        </div>
      :''} */}

      {
      typeof intervention !== "undefined" && typeof intervention.settings !== "undefined" && typeof intervention.settings.coverPhoto !== "undefined" && intervention.settings.coverPhoto.length > 0 ?
        <div className="illustration">
          <img src={intervention.settings.coverPhoto} />
        </div>
      :
        ''
      }

      <div className="lessonsHolder">
        <div className="intro">
          <h2>{typeof intervention.settings.menu != "undefined" ? intervention.settings.menu.modules:''}</h2>
          <span dangerouslySetInnerHTML={{__html: lessonsIntro}}></span>
        </div>
        {
          typeof intervention.settings.selfhelp.include_progressbar !== 'undefined' && intervention.settings.selfhelp.include_progressbar ?
          <>
            <LessonsProgressbar progress={progressBarProgress} popoverText={progressBarPopoverText} />
          </> : <></>
        } 
        {
            auth.doT0 ? 
            <div className="items" style={{marginTop: '70px', background: 'none'}} onClick={()=>goBaseline()}>
              <div className="item active">
                  <span style={{paddingLeft: '20px'}}>{t("Start hier: Waar sta je nu?")}</span>
              </div> 
            </div> : <></>
          }
        <div className="items">
        {

            lessonList.map((lesson, index) => {

              if (auth.doT0) {
                lesson.status = 'closed'
              }

              if (lesson.title.length > 8 && lesson.title.substr(0, 9).toLowerCase() === '[heading]') {

                return  (
                <div key={index} className="item" style={{fontWeight:"bold", marginTop: "20px"}}>{lesson.title.substr(9)}</div>
                )
              } else {

                return (
                    <div key={index} className={'item '+lesson.status}  onClick={(lesson.status === 'finished' || lesson.status === 'active') ? ()=>changeActiveLesson(lesson.id, index) : ()=>fakeEmptyFunc()}>
                        {
                        lesson.status === 'finished' ?
                            <img src={doneSVG}/>
                            :
                            <>
                                {
                                lesson.status === 'active' ?
                                    <img src={activeSVG} />
                                    :
                                    <img src={lockedSVG} />
                                }
                            </>
                        }
                        <span className="lessonTitle">{lesson.title}</span>
                    </div>
                )
              }
            })
        }
        </div>
      </div>
      {endCourse && parseInt(intervention.id) !== 55  ?
        <ConfettiFullScreen setEndCourse={setEndCourse}/>
        :
        false
      }
      <OptionalLessons lessonList={lessonList}/>

      <NotificationBox options={notificationOptions} setNotificationOptions={setNotificationOptions} />
    </div>
  )
}

export default Lessons;
