import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import parse from 'html-react-parser';
import t from "../../../translate";

const Feedback = (props) => {

  const intervention  = useSelector(state => state.intervention);
  const allAnswers = useSelector(state => state.answersLessons);

  const [answers, setAnswers] = useState(false);
  const [chosenOptionsText, setChosenOptionsText] = useState([]);
  const [chosenText, setChosenText] = useState('');
  const [feedback, setFeedback] = useState([]);
  const [question, setQuestion] = useState('');
  const [questionType, setQuestionType] = useState('question_open');
  let this_answer_obj;

  //////////////////////
  ///Get feedback questions
  useEffect(() => {
    setQuestion(props.part.question)

    /// get question
    let allLessons = intervention.settings.selfhelp.lessons;
    let this_lesson_obj = allLessons.filter(function (lesson) {
      return parseInt(lesson.id) === parseInt(props.part.refererIdInLesson)
    });
    if (this_lesson_obj.length < 1) {
      let allLessons = intervention.settings.selfhelp.optionalLessons;
      this_lesson_obj = allLessons.filter(function (lesson) {
        return lesson.id === props.part.refererIdInLesson
      });
    }

    if(this_lesson_obj.length > 0)
    {
      let this_question_obj = false;
      for (let i = 0; i < this_lesson_obj.length; i++) {
        this_question_obj = this_lesson_obj[i].settings.parts.filter(function (part) {
          return part.id === props.part.refererId
        });
        if (this_question_obj && this_question_obj.length > 0) {
          break;
        }
        
      }

      if(this_question_obj && this_question_obj.length > 0)
      {
        setQuestionType(this_question_obj[0].type)
        /// get answer
        let answersOfLesson = allAnswers.answers.filter(function (answer) {
          return answer.the_id === props.part.refererIdInLesson
        });

        if(answersOfLesson.length != 0)
        {
          this_answer_obj = answersOfLesson[0].answers.filter(function (answer) {
            return answer.id === props.part.refererId
          });

          if(this_answer_obj.length != 0)
          {
            setAnswers(this_answer_obj[0].answer)
            /// gekozen antwoorden array optie zetten
            if(this_question_obj[0].type == "question_open" || this_question_obj[0].type == "slider")
            {
              setChosenText(this_answer_obj[0].answer);
            } 
            else
            {

              /// meerdere opties mogelijk <= zoek ze
              let tempChosenOptionsText = [];
              for(let i = 0 ; i < this_question_obj[0].items.length ; i++)
              {
                if(this_answer_obj[0].answer.chosenAnswers.includes(this_question_obj[0].items[i].id))
                {
                  if (this_question_obj[0].items[i].type === 'other')
                  {
                    if ("undefined" !== typeof this_answer_obj[0].answer.otherAnswers && this_answer_obj[0].answer.otherAnswers.length > 0 && "undefined" !== typeof this_answer_obj[0].answer.otherAnswers[0].content) {
                      tempChosenOptionsText.push({
                        "content" : this_answer_obj[0].answer.otherAnswers[0].content,
                      });
                    } else {
                      tempChosenOptionsText.push({
                        "content" : t("Anders"),
                      });
                    }
                    
                  }
                  else
                  {
                    tempChosenOptionsText.push(this_question_obj[0].items[i]);
                  }
                }
              }
              setChosenOptionsText(tempChosenOptionsText)
            }
          }
        }
      }

    }

    if(props.part.subtype == "feedback"){
      //// zet feedback in array
      let tempFeedback = [];
      for(let i = 0 ; i < props.part.items.length ; i++)
      {
        if(typeof this_answer_obj != "undefined" && this_answer_obj.length > 0){
          if(this_answer_obj[0].answer.chosenAnswers.includes(props.part.items[i].refererId))
          {
            tempFeedback.push(props.part.items[i])
          }
        }
      }
      setFeedback(tempFeedback)
    }

  }, [props, allAnswers]);

  function getChosenText(question, this_answer_obj, this_question_obj_index){
    if(question.type != "question_open")
    {
      let tempChosenOptionsText = [];
      for(let i = 0 ; i < props.parts[this_question_obj_index].items.length ; i++)
      {
        if(this_answer_obj[0].answer.chosenAnswers.includes(props.parts[this_question_obj_index].items[i].id))
        {
          tempChosenOptionsText.push(props.parts[this_question_obj_index].items[i])
        }
      }
      setChosenOptionsText(tempChosenOptionsText)
    } else {
      setChosenOptionsText('haal tekst uit veld op')
    }
  }

  return(
    <div className='feedback'>
      <div className="center">
        {question != false ?
          <div>
            <h3 className="question">
              {parse(question)}
            </h3>
            {answers == false ?
              <div>{t("Je had deze vraag niet beantwoord")}</div>
            :
              <div>
                {props.part.subtype == "feedback" ?
                  <div className="feedbackOptions">
                    {feedback.map((feedbackItem, index) =>
                      <div key={index}>
                        {question.subtype == "radio" ?
                        <div>
                        {(typeof chosenOptionsText[index].correct != "undefined" && chosenOptionsText[index].correct ?
                          <div>
                            {t("Goed zo")}
                          </div>
                          :
                          <div>
                            {t("fout")}
                          </div>)}
                        </div>
                        : ''}
                        {parse(feedbackItem.content)}
                      </div>
                    )}
                  </div>
                :
                ''}
                {props.part.subtype == "herhaal antwoord" && questionType != "question_open" && questionType != "slider" ?
                  <div className="repeat">
                  {
                  chosenOptionsText.length > 0 ?
                      chosenOptionsText.map((chosenOption, index) =>
                        <div key={index}>
                          {parse(chosenOption.content)}
                        </div>
                      )
                      :
                      t("Je had deze vraag niet beantwoord")
                  }
                  </div>
                :
                  <div>
                  {chosenText.trim().length > 0 && chosenText.trim() !== "<br>" ? parse(chosenText) : t("Je had deze vraag niet beantwoord")}
                  </div>
                }
              </div>
            }
          </div>
        :
          <div>
            {question == false ?
              <div>
                {t("Er is geen vraag gekoppeld...")}
              </div>
              :''}
          </div>
        }
      </div>
    </div>
  )
}

export default Feedback;
