import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import LeftBottom from "./leftBottom.js";
import t from "../../translate";
import MenuItems from "./menu_items.js";
import LessonMenu from "./lesson_menu.js";
import BaselineMenu from "../../questionnaires/baseline/menu";
import { setActivePart, setActiveLesson, setActiveSubLesson } from "../../../actions/index.js";
import backSVG from "../../../images/course/standard/svg/back.svg";

let animateTimeout = false;

const DashBoardLeft = (props) => {

  const dispatch = useDispatch();
  const history = useHistory();

  const intervention = useSelector(state => state.intervention);
  const activePart = useSelector(state => state.activePart);

  const [animated, setAnimated]= useState(false)

  useEffect(() => {

    setAnimated(false);

    clearTimeout(animateTimeout);
    animateTimeout = setTimeout(() => {
      setAnimated(true)
    }, 1000)

  }, [activePart]);

  const load = (part) => {
        dispatch(setActiveLesson(0));
        dispatch(setActiveSubLesson(0));
        dispatch(setActivePart(part));
        history.push("/course/" + intervention.id + '/' + part);
  }

  return (
  <div className="left">
    <div className="content">
      {
        (activePart !== 'lesson' && activePart !== 'optional-lesson') && activePart !== 'baseline' ?
          <div>
            <div className={"menu" + (props.hideMenu ? " hide_mobile":"")}>
              <h2 className="title" dangerouslySetInnerHTML={{__html: intervention.title.replace('(controle)','')}}></h2>
              <MenuItems />

            </div>
          </div>
        :
          <div className="clearfix">
            <div>
              <span className="btn back" onClick={()=>load('lessons')}>
                {t("Terug naar het dashboard")}
                <img src={backSVG} style={{float: 'right'}} />
              </span>
            </div>
            <div className="menu">
              {
                activePart !== 'baseline' ?
                  <LessonMenu />
                  :
                  <BaselineMenu t0Finished={props.t0Finished} />
              }
            </div>
          </div>
        }
      <LeftBottom />
    </div>

      {/* <div className={'shapes' + (animated ? '':' animate')}>
        <div className='shape'></div>
        <div className='shape'></div>
        <div className='shape'></div>
        <div className='shape'></div>
      </div> */}
  </div>
  );
};

export default DashBoardLeft;
