import React, { useEffect, useState } from "react";
import Matrix from "../../../content/front/parts/matrix.js";
import QuestionOpen from "../../../content/front/parts/question_open.js";
import QuestionWithOptions from '../../../content/front/parts/question_with_options.js';
import Select from "../../../content/front/parts/select.js";
import Slider from "../../../content/front/parts/slider.js";
import DatePicker from "../../../content/front/parts/datepicker.js";
import FeedbackQuestionnaire from "../../../content/front/parts/feedback_questionnaire.js";
import Wysiwyg from "../../../content/front/parts/wysiwyg.js";
import Image from "../../../content/front/parts/image.js";
import Special from "../../../content/front/parts/special.js";
import List from '../../../content/front/parts/list.js';
import Video from '../../../content/front/parts/video.js';
import Audio from '../../../content/front/parts/audio.js';
import Quote from '../../../content/front/parts/quote.js';
import Feedback from '../../../content/front/parts/feedback.js';
import Chart from '../../../content/front/parts/chart.js';
import CustomElement from '../../../content/front/parts/custom.js'


const DynamicContent = (props) => {

    const [answers, setAnswers] = useState([]);

    useEffect(() => {
      setAnswers(props.answers)
    }, [props.answers])

    //////////////////////
    ///Get answer based on id
    const getAnswer = (id) => {
      let answer = answers.filter(function (answer) {
        return answer.id === id
      });

      if(Object.keys(answer).length)
      {
          return answer[0].answer
      } else {
        /// ze moeten leeg door indien leeg
        /// misschien andere opties ook nog anders
        if(props.part.type == "question_checkboxes" || props.part.type == "question_radio"){
          return{customAnswers:[],chosenAnswers:[],otherAnswers:[]}
        } else {
          return '';
        }

      }
    }

    const fakeUpdate = () => {}

    let dynamicContent = "";

    const [typesWeNeed , setTypesWeNeed] = useState(["matrix", "question_open", "question_checkboxes", "question_radio", "select", "slider", "custom", "feedback_questionnaire"]);

    useEffect(() => {
        if (props.showContext) {
            setTypesWeNeed(["matrix", "question_open", "question_checkboxes", "question_radio", "select", "slider", "custom", "datepicker", "feedback_questionnaire", "wysiwyg", "image", "special", "list", "video", "audio", "quote", "feedback", "chart"]);
        } else {
            setTypesWeNeed(["matrix", "question_open", "question_checkboxes", "question_radio", "select", "slider", "custom", "feedback_questionnaire"]);
        }
    }, [props.showContext])

    //const typesWeNeed = ["matrix", "question_open", "question_checkboxes", "question_radio", "select", "slider", "custom", "feedback_questionnaire"];

    if (typesWeNeed.indexOf(props.part.type) > -1)
    {
        switch (props.part.type) {
            case "datepicker":
                dynamicContent = <DatePicker index={props.part.id} part={props.part} updateAnswer={fakeUpdate} toggleMust="" must="false" disabled="true" answer={getAnswer(props.part.id)} />;
                break;
            case "question_open":
                dynamicContent = <QuestionOpen index={props.part.id} part={props.part} updatePart={fakeUpdate} toggleMust="" must="false" disabled="true" answer={getAnswer(props.part.id)} />;
                break;
            case "slider":
                dynamicContent =  <Slider part={props.part} index={props.part.id} updatePart={fakeUpdate} disabled="true" answer={getAnswer(props.part.id)} resetSetWasEmpty="true"/>;
                break;
            case "question_radio":
            case "question_checkboxes":
                dynamicContent = <QuestionWithOptions index={props.part.id} part={props.part} updateAnswer={fakeUpdate} answer={getAnswer(props.part.id)} disabled="true" />;
                break;
            case "select":
                dynamicContent = <Select index={props.part.id} part={props.part} updateAnswer={fakeUpdate} answer={getAnswer(props.part.id)} disabled="true" nextAllowed={false}/>
                break;
            case "matrix":
               dynamicContent = <Matrix index={props.part.id} part={props.part} updateAnswer={fakeUpdate} answer={getAnswer(props.part.id)} disabled="true" nextAllowed={false}/>
                break;
            case "feedback_questionnaire":
               dynamicContent = <FeedbackQuestionnaire index={props.part.id} part={props.part} updateAnswer={fakeUpdate} answers={props.answers} disabled="true" nextAllowed={false}/>
                break;
            case "wysiwyg":
                dynamicContent = <Wysiwyg index={props.part.id} part={props.part} updateAnswer={fakeUpdate} answers={props.answers} disabled="true" nextAllowed={false}/>
                break;
            case "image":
                dynamicContent = <Image index={props.part.id} part={props.part} updateAnswer={fakeUpdate} answers={props.answers} disabled="true" nextAllowed={false}/>
                break;
            case "special":
                dynamicContent = <Special index={props.part.id} part={props.part} updateAnswer={fakeUpdate} answers={props.answers} disabled="true" nextAllowed={false}/>
                break;
            case "list":
                dynamicContent = <List index={props.index} part={props.part} updateAnswer={props.updateAnswer} answer={props.answer} nextAllowed={false} />;
                break;
            case "video":
                dynamicContent = <Video part={props.part} nextAllowed={false} />;
                break;
            case "audio":
                dynamicContent = <Audio part={props.part} nextAllowed={false} />;
                break;
            case "quote":
                dynamicContent = <Quote part={props.part} nextAllowed={false} />;
                break;
            case "feedback":
                dynamicContent = <Feedback part={props.part} type={props.type} interventionSettings={props.interventionSettings} allAnswers={props.allAnswers} nextAllowed={false} />;
                break;
            case "chart":
                dynamicContent = <Chart part={props.part} nextAllowed={false} />;
                break;
            case "custom":
                dynamicContent = <CustomElement index={props.index} part={props.part} updateAnswer={props.updateAnswer} answer={props.answer} intervention_id={props.intervention_id} pagesHistory={props.pagesHistory} updatePageHistory={props.updatePageHistory}/>;
                break;
        }
    }

    return dynamicContent;

}

export default DynamicContent
