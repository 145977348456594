const text_55 = {
  intervention: 55,
  description_short: "Verminder eindeloos piekeren en neem de regie over je gedachten terug.",
  step_2_title: "",
  description_part1: "",
  description_part2: "",
  description_part3: "",
}

export {text_55};
