import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ErrorPage from "../error/";
import LoadScreen from "../loadScreen";
import { setActivePart, setActiveLesson, setActiveSubLesson, setActivePage, setActiveGoal } from "../../actions";
import { useLocation } from 'react-router-dom'
import DashBoardLeft from "./content/dashboard_left.js";
import LeftMenu from "../leftmenu";
import Rewards from "../rewards";
import Content from "./content.js"; // er is ook een map content, daarom explicit .js

const Course = (props) => {

  /// window location href fired niet na change via history.goBack() location.pathname wel
  let location = useLocation();

  const dispatch = useDispatch();

  const activePart = useSelector(state => state.activePart);
  const activeLesson = useSelector(state => state.activeLesson);
  const activeSubLesson = useSelector(state => state.activeSubLesson);

  //TODO deze allowed kan er helamaal uit denk ik...
  let allowed = true;

  /// check of badges aan staan en of de datum van start indien gezet later is
  const [badgesActive, setBadgesActive] = useState(false);
  const intervention = useSelector(state => state.intervention);
  const auth = useSelector(state => state.auth);

  useEffect(() => {
    if(auth.user_id > 0 && typeof intervention.settings.gamification != "undefined" && intervention.settings.gamification.badges){
      if(typeof intervention.settings.gamification.badgesFromDateActive != "undefined" && typeof intervention.settings.gamification.badgesFromDate != "undefined" && intervention.settings.gamification.badgesFromDateActive){
        if(typeof intervention.settings.gamification.badgesFromDate[0] != "undefined" && intervention.settings.gamification.badgesFromDate[0] != ""){
          var date = intervention.settings.gamification.badgesFromDate[0].split("T")[0].split("-");
          var timestamp_to_check = new Date( date[0], date[1] - 1, date[2]) / 1000 ;
          if(timestamp_to_check < parseInt(auth.rights.interventions[0].date_time_start) &&  parseInt(auth.rights.interventions[0].date_time_start) != ""){
            setBadgesActive(true);
          }
        }
      } else {
        setBadgesActive(true);
      }
    }
  }, [auth, intervention]);

  //bij wijzigen url uitlezen en juiste activePart vaststellen
  useEffect(() => {

    //// is er ergens nog een check of je de desbetreffende pagina wel mag zien... een ander id geeft een error in de les
    //i.g.v. reload wordt via de url vastgesteld of er een specifiek onderdeel geladen moet worden
    let querystring = window.location.href.split("/");
    let allowedParts = ["lessons", "lesson", "optional-lesson", "goals", "journal", "stress", "chat", "settings", "more", "page", "goal", "goal-edit", "badges", "sleep-diary"];
    if (querystring.length > 5 && querystring[5].length > 0 && activePart !== querystring[5] && allowedParts.indexOf(querystring[5]) > -1)
    {
      dispatch(setActivePart(querystring[5]));
      //igv lesson, activeLesson vastleggen
      if (querystring[5] === 'lesson' && querystring.length > 6 && querystring[6].length > 0 && activeLesson !== querystring[6] && !isNaN(querystring[6]))
      {

        dispatch(setActiveLesson(querystring[6]));
        //sublesson? Uitgeschakeld ivm routing...
        /*
        if (querystring.length > 7 && querystring[7].length > 0 && activeSubLesson !== querystring[7] && !isNaN(querystring[7]))
        {
          dispatch(setActiveSubLesson(querystring[7]));
        }
        */
      }
      //igv optionele les, activePage vastleggen
      if (querystring[5] === 'optional-lesson' && querystring.length > 6 && querystring[6].length > 0 && activeLesson !== querystring[6] && !isNaN(querystring[6]))
      {
        dispatch(setActiveLesson(querystring[6]));
      }
      //igv page, activePage vastleggen
      if (querystring[5] === 'page' && querystring.length > 6 && querystring[6].length > 0 && activeLesson !== querystring[6] && !isNaN(querystring[6]))
      {
        dispatch(setActivePage(querystring[6]));
      }
      //igv goal, activeGoal vastleggen
      if (querystring[5] === 'goal' && querystring.length > 6 && querystring[6].length > 0 && activeLesson !== querystring[6] && !isNaN(querystring[6]))
      {
        dispatch(setActiveGoal(querystring[6]));
      }

    }
      //// dankzijn location.pathname doet de back funtie in de mobiele chat het wel
  }, [window.location.href, location.pathname]);

  //bij wijzigen part naar top scherm scrollen
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activePart, activeLesson, activeSubLesson]);


  ///2022-4-21 om het menu te verbergen indien de badgeoverlay zichtbaar is (position fixed mobiel )
  const [hideMenu, setHideMenu] = useState(false)

  return (
    <div>
      <LeftMenu />
      {allowed ? (
        <>
        <div className={(activePart == 'lesson' || activePart == 'optional-lesson') ? 'dashboard lessoncontent front':"dashboard student " + activePart}>
          <div className="clearfix holder">
            <DashBoardLeft hideMenu={hideMenu}/>
            <div className="right">
              {/* <div className={(activePart !== 'lesson' && activePart !== 'optional-lesson') ? 'shapes phone' : 'hidden'}>
                <div className='shape'></div>
                <div className='shape'></div>
                <div className='shape'></div>
                <div className='shape'></div>
              </div> */}
              <Content badgesActive={badgesActive}/>
              {badgesActive ? <Rewards setHideMenu={setHideMenu}/> :false}
            </div>
          </div>

        </div>
        </>
      ) : (
        <div>
          {allowed == "loading" ? (
            <div>
              <LoadScreen />
            </div>
          ) : (
            <div>
              <ErrorPage />
            </div>
          )}
        </div>
      )}

    </div>
  );
};

export default Course;
