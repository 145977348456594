import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import apiCall from "../api";
import HighlightRound from "./content/highlight_round.js";
import InterventionData from "./content/intervention.js";
import Registrations from "./content/registrations.js";
import RecentHighlights from "./content/recent_highlights.js";
import LoadScreen from "../loadScreen";
import ChartBar from "../content/front/parts/chart/bar.js";
import t from "../translate";
import { use } from "react";

let timerGetSelections = null;

const InterventionStats = (props) => {

  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [afgerond, setAfgerond] = useState(0);
  const [showPart, setShowPart] = useState("start");
  const [statsSelections, setStatsSelections] = useState([]);
  const [showAddSelectionForm, setShowAddSelectionForm] = useState(false);
  const [dateFrom, setDateFrom] = useState("");
  const [dateUntil, setDateUntil] = useState("");
  const [errorMessage, setErrorMessage] = useState([]);

  const [interventions, setInterventions] = useState(null);
  const [showSelection, setShowSelection] = useState(false);


  useEffect(() => { 
    //berekenen actieve gebruikers = gebruiker die minimaal 1 les heeft geopend, dus gestart is
    let nr_afgerond = 0
    if (typeof props.activity?.total?.nr_opened_lessons !== 'undefined') {
      Object.keys(props.activity?.total?.nr_opened_lessons).map(function(item, index)  {
        Object.keys(props.activity?.total?.nr_opened_lessons[item]).map(function(key_, index_)  {
          if (key_ > 0) {
            nr_afgerond += parseInt(props.activity?.total?.nr_opened_lessons[item][key_])
          }
        })
      })
    }
    setAfgerond(nr_afgerond)
    getSelections()
    setIntervalGetSelections()
  }, [props]);

  const setIntervalGetSelections = () => {
    clearInterval(timerGetSelections)
    timerGetSelections = setInterval(() => {
        getSelections()
    }, 30000)
  }

  const getSelections = () => {
      apiCall({
        action: "get_stats_selections",
        token: auth.token,
        data: {}
      }).then(resp => {
        setStatsSelections(resp.selections)
      });
  }

  const requestNewSelection = () => {
    setErrorMessage("")
    apiCall({
        action: "request_stats_selection",
        token: auth.token,
        data: {
            from_date: dateFrom,
            until_date: dateUntil
        }
      }).then(resp => {
        if (resp.success) {
            getSelections()
            setDateFrom("")
            setDateUntil("")
            setShowAddSelectionForm(false)
        } else {
            setErrorMessage(resp.msg)
        }
        getSelections()
      });
  }

  const setSelection = (id) => {
    apiCall({
        action: "get_stats_selection",
        token: auth.token,
        data: {
            selection_id : id
        }
      }).then(resp => {
        if (resp.success) {
            props.changeToSelection(resp.selection)
        } else {
            alert(resp.msg)
        }
      })

      setShowSelection(true)

  }

  return (
    <>
    {props.activity && props.registrations && props.interventions ?
      <div className="stats block">
        <div className="nav_stats">
          {
            /*
            <h1>{t("Statistieken")}</h1>
            */
          }

          <ul>
            <li></li>
            <li onClick={()=>setShowPart('start')} className={showPart == "start" ? 'active':''}>
              {t("Overzicht")}
            </li>
          </ul>
          <ul>
            <li>
              <b>{t("Activiteiten")}</b>
            </li>
            {
                !showSelection ?
                    <>
                        <li onClick={()=>setShowPart('24_hour')} className={showPart == "24_hour" ? 'active':''}>
                          {t("Afgelopen 24 uur")}
                        </li>
                        <li onClick={()=>setShowPart('week')} className={showPart == "week" ? 'active':''}>
                          {t("Afgelopen week")}
                        </li>
                        <li onClick={()=>setShowPart('month')} className={showPart == "month" ? 'active':''}>
                          {t("Afgelopen maand")}
                        </li>
                    </>
                    : <></>
            }
                <li onClick={()=>setShowPart('logins')} className={showPart == "logins" ? 'active':''}>
                  {t("Aantal logins")}
                </li>            
          </ul>

          <ul>
            <li><b>{t("Interventies")}</b></li>
            <li onClick={()=>setShowPart('interventions')} className={showPart == "interventions" ? 'active':''}>
              {t("Interventies")}
            </li>
          </ul>

          <ul>
            <li>
              <b>{t("Registraties")}</b>
            </li>
            <li onClick={()=>setShowPart('registrations')} className={showPart == "registrations" ? 'active':''}>
              {t("Alle (incl niet afgeronde)")}
            </li>
            <li onClick={()=>setShowPart('registrations_finished')} className={showPart == "registrations_finished" ? 'active':''}>
              {t("Alle afgeronde")}
            </li>
            <li onClick={()=>setShowPart('registrations_activated')} className={showPart == "registrations_activated" ? 'active':''}>
              {t("Alle afgeronde en geactiveerde")}
            </li>
            {
                !showSelection ?
                    <>
                        <li onClick={()=>setShowPart('registrations_24')} className={showPart == "registrations_24" ? 'active':''}>
                          {t("Afgelopen 24 uur")}
                        </li>
                        <li onClick={()=>setShowPart('registrations_week')} className={showPart == "registrations_week" ? 'active':''}>
                          {t("Afgelopen week")}
                        </li>
                        <li onClick={()=>setShowPart('registrations_month')} className={showPart == "registrations_month" ? 'active':''}>
                          {t("Afgelopen maand")}
                        </li>
                    </>
                    : <></>
            }
          </ul>

          <ul>
            <li>
                <span><strong>{t("Selecties")}</strong> &nbsp;
                {
                    showAddSelectionForm ?
                        <i className="fa fa-minus pointer" onClick={() => setShowAddSelectionForm(false)}></i>
                        :
                        <i className="fa fa-plus pointer" onClick={() => setShowAddSelectionForm(true)}></i>
                }
                </span>
            <span>
                {
                    showAddSelectionForm ?
                        <div className="add_selection_form" style={{backgroundColor: 'white', padding: '10px'}}>
                            <input type="text" className="form-control" placeholder={t("van dd-mm-yyyy")} id="from_date" value={dateFrom} onChange={(e) => setDateFrom(e.target.value)} />
                            <input type="text" className="form-control" placeholder={t("t/m dd-mm-yyyy")} id="data_until" value={dateUntil} onChange={(e) => setDateUntil(e.target.value)} />
                            <span className="btn btn-primary" onClick={() => requestNewSelection()} style={{marginTop: '10px', float: 'right'}}>Add</span>&nbsp; 
                            {
                                setErrorMessage.length > 0 ?
                                    <div style={{color: 'red', fontSize: '0.7em', display: 'block'}}><br /><br />{errorMessage}</div>
                                    : <></> 
                            }
                            
                        </div>
                        : <></>
                }
            </span>

            </li>
            <li onClick={() => props.changeToAll()}>{t("Gehele duur")}</li>
            {
                statsSelections.map((selection, index) => {
                    if (parseInt(selection.status) === 1) {
                        return (
                            <li key={index} onClick={()=>setSelection(selection.id)} style={{fontSize: '0.8em', cursor: 'pointer'}}>
                                {selection.from_date} - {selection.until_date}
                            </li>
                        )
                    } else {
                        return (
                            <li key={index} style={{color: 'red', fontSize: '0.8em'}}>
                                {selection.from_date} - {selection.until_date}
                            </li> 
                        )
                    }
                })            
            }
          </ul>
        </div>
        <div className="all_content">
        {showPart == "start" ?
          <>
          <HighlightRound icon="fa fa-users" label="Gebruikers" content={props.activity.total.active_accounts} />
          <HighlightRound icon="fas fa-door-open" label="Logins" content={props.activity.total.logins} />
          <HighlightRound icon="fas fa-door-open" label="Actieve gebruikers" content={afgerond} />
          <br/>
          <HighlightRound icon="fas fa-mail-bulk" label="Vragenlijsten verstuurd" content={props.activity.total.questionnaires_total_invites} color="orange" />
          <HighlightRound icon="fas fa-check" label="Vragenlijsten afgerond" content={props.activity.total.questionnaires_total_finished} color="orange" />
          {/* <HighlightRound icon="fas fa-door-open" label="Conversie" content="Berekenen" color="orange" /> */}
          <br/>
          <HighlightRound icon="fa fa-language" label="Alle registraties" content={props.registrations.all_registrations.total} color="yellow"/>
          <HighlightRound icon="fa fa-language" label="Afgeronde registraties" content={props.registrations.all_finished_registrations.total} color="yellow"/>
          <HighlightRound icon="fa fa-language" label="Geactiveerde registraties" content={props.registrations.all_activated_registrations.total} color="yellow"/>
          {/* <HighlightRound icon="fas fa-door-open" label="Conversie" content="Berekenen" color="yellow" /> */}
          <br/>
          <HighlightRound icon="fa fa-language" label="Nederlands" content={props.activity.total.language[1]} color="dark_bleu" />
          <HighlightRound icon="fa fa-language" label="Engels" content={props.activity.total.language[2]} color="dark_bleu"/>
          </>
        :false}
        {showPart == "24_hour" ?
          <RecentHighlights header="Activiteiten afgelopen 24 uur" data={props.activity.day} getInterventionTitle={props.getInterventionTitle}/>
        :false
        }
        {showPart == "week" ?
          <RecentHighlights header="Activiteiten afgelopen week" data={props.activity.week} getInterventionTitle={props.getInterventionTitle}/>
        :false
        }
        {showPart == "month" ?
          <RecentHighlights header="Activiteiten afgelopen maand" data={props.activity.month} getInterventionTitle={props.getInterventionTitle}/>
        :false
        }
        {showPart == "interventions" ?
          <>
          {Object.keys(props.activity.total.intervention).length > 0 ?
            <div className="interventions">
              {Object.keys(props.activity.total.intervention).map(function(key, index) {
                return(
                  <>
                  <InterventionData getInterventionCoverPhoto={props.getInterventionCoverPhoto} activity={props.activity} mainlessons={props.mainlessons} optionallessons={props.optionallessons} okey={key} getInterventionTitle={props.getInterventionTitle}/>
                  </>
                )
              })}
            </div>
            :false}
          </>
        :false
        }
        {showPart == "logins" ?
          <>
          <h3>{t("Aantal logins")}</h3>
          <ChartBar
          colors="#fabc5a"
          part={{label_value:t("Studenten"),label_items:t("Aantal inlogs")}}
          labels={Object.keys(props.activity.total.nrlogins)}
          values={Object.keys(props.activity.total.nrlogins)
            .map(function(key) {
                return props.activity.total.nrlogins[key];
            })} />
          <div className="box logins">
            {Object.keys(props.activity.total.nrlogins).map(function(key, index) {
              return(
                <div className="login">
                  <b>{props.activity.total.nrlogins[key]}</b> {props.activity.total.nrlogins[key] == 1 ? t("student"):t("studenten")} =&gt; <b>{key}</b> {t("ingelogd")}
                </div>
              )
            })}
          </div>
          </>
        :false
        }
        {showPart == "registrations" ?
          <>
          <Registrations header="Alle registraties (incl niet afgeronde)" data={props.registrations.all_registrations}  show_t={true}/>
          </>
        :false
        }
        {showPart == "registrations_finished" ?
          <>
          <Registrations header="Alle afgeronde registraties" data={props.registrations.all_finished_registrations} />
          </>
        :false
        }
        {showPart == "registrations_activated" ?
          <>
          <Registrations header="Alle afgeronde en geactiveerde registraties" data={props.registrations.all_activated_registrations} />
          </>
        :false
        }
        {showPart == "registrations_24" ?
          <>
          <Registrations header="Alle registraties vd afgelopen 24 uur" data={props.registrations.all_registrations_day}  show_t={true}/>
          </>
        :false
        }
        {showPart == "registrations_week" ?
          <>
          <Registrations header="Alle registraties vd afgelopen week" data={props.registrations.all_registrations_week}  show_t={true}/>
          </>
        :false
        }
        {showPart == "registrations_month" ?
          <>
          <Registrations header="Alle registraties vd afgelopen maand" data={props.registrations.all_registrations_month} show_t={true}/>
          </>
        :false
        }
        </div>

      </div>
      :
      <LoadScreen/>
    }
    </>
  );
};

export default InterventionStats;
